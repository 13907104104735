export function getGibsAttributeKey(input: string): string {
    let output = input;
    if (typeof(input) !== 'undefined' && input != null) {
        const briteToken = '_brite';
        const endOfKey = input.indexOf(briteToken);
        if (endOfKey > 0)
        {
            output = input.slice(0, endOfKey);
        }
    }
    return output;
}


export function isGuid(input: string): Boolean {
    let output = false;
    const isGuidRe = /^([\da-z]){8}-([\da-z]){4}-([\da-z]){4}-([\da-z]){4}-([\da-z]){12}$/ig;

    if (typeof(input) !== 'undefined' && input != null) {
        if (isGuidRe.test(input)) {
            output = true;
        } 
    }
    return output;
}

export function newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
        v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}